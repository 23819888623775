import React from 'react';

export const IconBlockchainDevelopment = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="68"
      fill="none"
      viewBox="0 0 68 68"
    >
      <circle cx="34" cy="34" r="27.25" fill="#CACDD5" opacity="0.2"></circle>
      <circle cx="34" cy="34" r="33.5" stroke="#052148"></circle>
      <g fill="#052148" clipPath="url(#clip0_5117_16244)">
        <path d="M48.143 26.267a2.464 2.464 0 00-2.461-2.461c-.697 0-1.326.291-1.775.758l-9.603-5.544a.586.586 0 00-.586 0l-9.604 5.544a2.453 2.453 0 00-1.774-.758 2.464 2.464 0 00-2.461 2.46c0 1.156.8 2.127 1.875 2.39v11.086c0 .21.112.403.293.507l9.6 5.543a2.464 2.464 0 002.363 3.149 2.464 2.464 0 002.363-3.148l9.602-5.544a.586.586 0 00.293-.507V28.657a2.465 2.465 0 001.875-2.39zm-27.092 0c0-.711.578-1.29 1.289-1.29a1.29 1.29 0 010 2.579 1.29 1.29 0 01-1.29-1.29zm12.96 21.502a1.29 1.29 0 01-1.29-1.289 1.29 1.29 0 012.578 0 1.29 1.29 0 01-1.289 1.29zm1.775-2.99a2.454 2.454 0 00-3.55 0l-9.31-5.375V28.656a2.465 2.465 0 001.776-3.078l9.309-5.374 9.309 5.374a2.465 2.465 0 001.776 3.079v10.747l-9.31 5.375zm9.896-17.223a1.29 1.29 0 01-1.29-1.29 1.29 1.29 0 012.578 0c.001.711-.577 1.29-1.288 1.29z"></path>
        <path d="M42.039 28.03l-7.735-4.465a.586.586 0 00-.585 0l-7.735 4.466a.586.586 0 00-.293.507v8.931c0 .21.112.403.293.507l7.735 4.466a.586.586 0 00.585 0l7.735-4.466a.586.586 0 00.293-.507v-8.93a.586.586 0 00-.293-.508zm-8.028-3.28l6.563 3.788-6.563 3.79-6.562-3.79 6.562-3.788zm.586 16.17v-2.643a.586.586 0 10-1.171 0v2.643l-6.563-3.79v-7.577l6.856 3.958a.586.586 0 00.585 0l6.856-3.958v7.578l-6.563 3.789z"></path>
        <path d="M34.01 35.054a.59.59 0 00-.587.586.59.59 0 00.588.586.59.59 0 00.585-.586.59.59 0 00-.585-.586z"></path>
      </g>
      <defs>
        <clipPath id="clip0_5117_16244">
          <path
            fill="#fff"
            d="M0 0H30V30H0z"
            transform="translate(19 19)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};
