import React from 'react';

export const IconLinkedin = (props) => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.32" x="0.5" y="0.5" width="43" height="43" rx="3.5" stroke="#FBFBFE" />
    <g opacity="0.64" clipPath="url(#clip0_0_8)">
      <path
        d="M33 30.8009C33 32.0162 32.007 33 30.7825 33H13.2175C11.993 33 11 32.0149 11 30.8009V13.1991C11 11.9851 11.993 11 13.2175 11H30.7825C32.0083 11 33 11.9851 33 13.1991V30.8009Z"
        fill="#FBFBFE"
      />
      <path
        d="M18.1565 16.3796C18.1565 17.1877 17.5525 17.8428 16.5596 17.8428C15.6255 17.8428 15.0215 17.1877 15.0215 16.3796C15.0215 15.5526 15.6446 14.9165 16.5992 14.9165C17.5525 14.9165 18.1373 15.5513 18.1565 16.3796ZM15.0995 28.4071V18.9961H18.0592V28.4071H15.0995Z"
        fill="#000E33"
      />
      <path
        d="M19.8298 21.9981C19.8298 20.8246 19.7914 19.8433 19.7517 18.996H22.3224L22.4593 20.3048H22.5182C22.9072 19.689 23.8618 18.7848 25.4587 18.7848C27.4062 18.7848 28.8662 20.0747 28.8662 22.8454V28.407H25.9065V23.1919C25.9065 21.9791 25.4779 21.1521 24.4069 21.1521C23.5892 21.1521 23.103 21.7098 22.888 22.2485C22.8099 22.4407 22.7907 22.7101 22.7907 22.9794V28.407H19.8298V21.9981Z"
        fill="#000E33"
      />
    </g>
    <defs>
      <clipPath id="clip0_0_8">
        <rect width="22" height="22" fill="white" transform="translate(11 11)" />
      </clipPath>
    </defs>
  </svg>
);
