import * as React from 'react';

export const IconPinterestDark = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 15.93c0 8.685 7.15 15.726 15.974 15.726 8.821 0 15.973-7.041 15.973-15.725S24.795.207 15.974.207C7.15.207 0 7.247 0 15.931ZM10.678 29.2c-5.384-2.076-9.2-7.233-9.2-13.269 0-7.872 6.481-14.25 14.476-14.25 7.996 0 14.477 6.378 14.477 14.25 0 7.87-6.481 14.25-14.477 14.25-1.493 0-2.932-.222-4.286-.637.59-.946 1.472-2.497 1.799-3.735.176-.667.901-3.387.901-3.387.473.886 1.852 1.637 3.32 1.637 4.364 0 7.51-3.953 7.51-8.864 0-4.707-3.902-8.23-8.924-8.23-6.246 0-9.564 4.129-9.564 8.624 0 2.088 1.13 4.692 2.936 5.52.276.126.423.07.486-.191l.133-.533.27-1.082a.425.425 0 0 0-.102-.41c-.599-.714-1.076-2.027-1.076-3.252 0-3.142 2.418-6.184 6.536-6.184 3.557 0 6.046 2.385 6.046 5.797 0 3.855-1.977 6.526-4.55 6.526-1.42 0-2.485-1.155-2.145-2.575.148-.612.346-1.241.538-1.854.34-1.082.662-2.11.662-2.89 0-1.095-.597-2.008-1.83-2.008-1.455 0-2.62 1.479-2.62 3.461 0 1.262.433 2.115.433 2.115S10.99 24 10.729 25.112c-.29 1.233-.178 2.962-.051 4.089Z"
      fill="#27292B"
    />
  </svg>
);
