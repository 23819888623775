import { Button } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { FC, ReactNode } from 'react';

const ButtonPrimary: FC<any> = ({
  className,
  children,
  loading,
  isButtonSecondary = false,
  isSquareBorder = false,
  isRawColorBackground = false,
  ...props
}) => {
  return (
    <Button
      className={classNames(
        'button-primary',
        {
          'button-secondary': isButtonSecondary,
          'button-square-border': isSquareBorder,
          'button-raw-color': isRawColorBackground,
        },
        className,
      )}
      {...props}
    >
      {loading ? (
        <img
          className="loading"
          src={!isButtonSecondary ? '/icons/loading-logo-white.gif' : '/images/loading-small-bg-white.gif'}
          alt="Loading"
        />
      ) : (
        children
      )}
    </Button>
  );
};

const ButtonLearnMore: FC<{
  text?: string;
  className?: string;
  icon?: ReactNode;
  isIconLeft?: boolean;
  onMouseMove?: any;
  onMouseLeave?: any;
  onClick?: any;
  isWhite?: boolean;
}> = ({ text, isWhite, className, icon, isIconLeft = false, ...props }) => {
  const { t } = useTranslation();

  return (
    <Button
      type="link"
      className={classNames('button-learn-more', className, {
        'icon-left': isIconLeft,
      })}
      icon={
        icon === null ? null : icon ? (
          icon
        ) : (
          <img
            src={isWhite ? '/icons/arrow-right-thin-icon-white.svg' : '/icons/arrow-right-thin-icon.svg'}
            alt="Learn more"
            className="icon"
          />
        )
      }
      {...props}
    >
      {text || t('common.txtExploreNow')}
    </Button>
  );
};

export { ButtonPrimary, ButtonLearnMore };
