import React from 'react';

export const IconPrivateBlockchain = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    {...props}
  >
    <circle opacity="0.2" cx="34" cy="34" r="27.25" fill="#CACDD5" />
    <circle cx="34" cy="34" r="33.5" stroke="#F15622" />
    <path
      d="M25.3658 40.0854C25.6086 40.0854 25.8053 39.8886 25.8053 39.6459V35.3271C25.8053 35.0845 25.6086 34.8877 25.3658 34.8877C25.123 34.8877 24.9263 35.0845 24.9263 35.3271V37.0471H21.5573C21.3762 36.5356 20.8882 36.1682 20.3163 36.1682C19.5904 36.1682 19 36.7596 19 37.4866C19 38.2135 19.5904 38.8049 20.3163 38.8049C20.8882 38.8049 21.3763 38.4375 21.5573 37.926H24.9263V39.6459C24.9263 39.8886 25.123 40.0854 25.3658 40.0854ZM20.3163 37.926C20.0751 37.926 19.8789 37.7289 19.8789 37.4866C19.8789 37.2442 20.0751 37.0471 20.3163 37.0471C20.5574 37.0471 20.7537 37.2442 20.7537 37.4866C20.7537 37.7289 20.5574 37.926 20.3163 37.926Z"
      fill="#F15622"
    />
    <path
      d="M29.1248 45.7787L25.8047 44.2146V43.1484C25.8047 42.9057 25.608 42.709 25.3652 42.709C25.1225 42.709 24.9258 42.9057 24.9258 43.1484V44.4934C24.9258 44.6636 25.024 44.8184 25.178 44.891L28.7503 46.5738C28.8108 46.6023 28.8745 46.6158 28.9372 46.6158C29.1021 46.6158 29.2601 46.5226 29.3351 46.3635C29.4384 46.1439 29.3443 45.8821 29.1248 45.7787Z"
      fill="#F15622"
    />
    <path
      d="M42.635 42.7085C42.3923 42.7085 42.1956 42.9053 42.1956 43.1479V44.2141L34.0007 48.0746L31.0409 46.6803C30.8213 46.5769 30.5595 46.6709 30.4561 46.8905C30.3526 47.1101 30.4468 47.3719 30.6664 47.4753L33.8135 48.9579C33.8728 48.9858 33.9368 48.9998 34.0007 48.9998C34.0647 48.9998 34.1288 48.9858 34.188 48.9579L42.8223 44.8905C42.9762 44.818 43.0745 44.6631 43.0745 44.4929V43.1479C43.0745 42.9053 42.8778 42.7085 42.635 42.7085Z"
      fill="#F15622"
    />
    <path
      d="M42.6348 34.8877C42.392 34.8877 42.1953 35.0845 42.1953 35.3271V39.6459C42.1953 39.8886 42.392 40.0854 42.6348 40.0854C42.8775 40.0854 43.0742 39.8886 43.0742 39.6459V37.926H46.4432C46.6243 38.4375 47.1122 38.8049 47.6843 38.8049C48.4101 38.8049 49.0005 38.2135 49.0005 37.4866C49.0005 36.7596 48.4101 36.1682 47.6843 36.1682C47.1123 36.1682 46.6243 36.5356 46.4432 37.0471H43.0742V35.3271C43.0742 35.0845 42.8775 34.8877 42.6348 34.8877ZM47.6843 37.0471C47.9254 37.0471 48.1216 37.2442 48.1216 37.4865C48.1216 37.7288 47.9254 37.926 47.6843 37.926C47.4431 37.926 47.2469 37.7288 47.2469 37.4865C47.2469 37.2442 47.4431 37.0471 47.6843 37.0471Z"
      fill="#F15622"
    />
    <path
      d="M25.3653 32.1964C25.608 32.1964 25.8047 31.9996 25.8047 31.7569V30.8894C25.8047 30.6278 26.0176 30.4149 26.2792 30.4149H41.7199C41.9815 30.4149 42.1944 30.6278 42.1944 30.8894V31.7569C42.1944 31.9996 42.3911 32.1964 42.6338 32.1964C42.8766 32.1964 43.0733 31.9996 43.0733 31.7569V30.8894C43.0733 30.1431 42.4662 29.536 41.7199 29.536H41.3007V26.2802C41.3007 22.2659 38.0348 19 34.0205 19C30.0062 19 26.7403 22.2659 26.7403 26.2802V29.536H26.2791C25.5329 29.536 24.9258 30.1431 24.9258 30.8894V31.7569C24.9258 31.9996 25.1225 32.1964 25.3653 32.1964ZM27.6192 26.2802C27.6192 22.7505 30.4908 19.8789 34.0205 19.8789C37.5502 19.8789 40.4218 22.7505 40.4218 26.2802V29.536H39.5429V28.3227C39.5429 28.08 39.3462 27.8832 39.1034 27.8832C38.8607 27.8832 38.664 28.08 38.664 28.3227V29.536H29.377V26.2802C29.377 23.7198 31.46 21.6367 34.0205 21.6367C36.5809 21.6367 38.664 23.7198 38.664 26.2802C38.664 26.5229 38.8607 26.7197 39.1034 26.7197C39.3462 26.7197 39.5429 26.5229 39.5429 26.2802C39.5429 23.2352 37.0655 20.7578 34.0205 20.7578C30.9754 20.7578 28.4981 23.2352 28.4981 26.2802V29.536H27.6192V26.2802Z"
      fill="#F15622"
    />
    <path
      d="M28.2578 32.2407C27.6859 32.2407 27.198 32.6081 27.0169 33.1196H20.8913L19.7222 32.1372C19.5362 31.9811 19.2591 32.0051 19.103 32.1909C18.9469 32.3767 18.9709 32.6539 19.1567 32.8101L20.4485 33.8955C20.5277 33.962 20.6278 33.9985 20.7312 33.9985H27.0169C27.198 34.51 27.6859 34.8774 28.2578 34.8774C28.9835 34.8774 29.5739 34.286 29.5739 33.559C29.5739 32.8321 28.9835 32.2407 28.2578 32.2407ZM28.2578 33.9986C28.0168 33.9986 27.8206 33.8014 27.8206 33.5591C27.8206 33.3168 28.0168 33.1196 28.2578 33.1196C28.4989 33.1196 28.695 33.3168 28.695 33.5591C28.695 33.8014 28.4989 33.9986 28.2578 33.9986Z"
      fill="#F15622"
    />
    <path
      d="M28.2578 40.0786C27.6859 40.0786 27.198 40.446 27.0169 40.9575H20.7312C20.6278 40.9575 20.5276 40.994 20.4485 41.0605L19.1567 42.1459C18.9709 42.3021 18.9469 42.5793 19.103 42.7651C19.1899 42.8686 19.3143 42.9218 19.4397 42.9218C19.5395 42.9218 19.6398 42.888 19.7222 42.8188L20.8913 41.8364H27.0169C27.198 42.3479 27.6859 42.7153 28.2578 42.7153C28.9835 42.7153 29.5739 42.1239 29.5739 41.3969C29.5739 40.6699 28.9835 40.0786 28.2578 40.0786ZM28.2578 41.8364C28.0168 41.8364 27.8206 41.6393 27.8206 41.397C27.8206 41.1546 28.0168 40.9575 28.2578 40.9575C28.4989 40.9575 28.695 41.1546 28.695 41.397C28.695 41.6393 28.4989 41.8364 28.2578 41.8364Z"
      fill="#F15622"
    />
    <path
      d="M39.7419 32.2407C39.0162 32.2407 38.4258 32.8321 38.4258 33.5591C38.4258 34.286 39.0162 34.8774 39.7419 34.8774C40.3139 34.8774 40.8018 34.51 40.9829 33.9985H47.2685C47.3719 33.9985 47.4721 33.962 47.5512 33.8955L48.843 32.8101C49.0288 32.654 49.0528 32.3768 48.8967 32.191C48.7406 32.0051 48.4633 31.9811 48.2775 32.1372L47.1084 33.1197H40.9829C40.8018 32.6081 40.3139 32.2407 39.7419 32.2407ZM39.7419 33.9985C39.5009 33.9985 39.3047 33.8014 39.3047 33.5591C39.3047 33.3167 39.5009 33.1196 39.7419 33.1196C39.983 33.1196 40.1791 33.3167 40.1791 33.5591C40.1791 33.8014 39.983 33.9985 39.7419 33.9985Z"
      fill="#F15622"
    />
    <path
      d="M48.843 42.1459L47.5512 41.0605C47.472 40.994 47.3719 40.9575 47.2685 40.9575H40.9829C40.8018 40.446 40.3139 40.0786 39.7419 40.0786C39.0162 40.0786 38.4258 40.67 38.4258 41.397C38.4258 42.1239 39.0162 42.7153 39.7419 42.7153C40.3139 42.7153 40.8018 42.3479 40.9829 41.8364H47.1084L48.2775 42.8189C48.3599 42.8881 48.4602 42.9219 48.56 42.9219C48.6854 42.9219 48.8098 42.8686 48.8967 42.7651C49.0528 42.5793 49.0288 42.3021 48.843 42.1459ZM39.7419 41.8364C39.5009 41.8364 39.3047 41.6393 39.3047 41.397C39.3047 41.1546 39.5009 40.9575 39.7419 40.9575C39.983 40.9575 40.1791 41.1546 40.1791 41.397C40.1791 41.6393 39.983 41.8364 39.7419 41.8364Z"
      fill="#F15622"
    />
    <path
      d="M34.0213 42.7001C34.7482 42.7001 35.3396 42.1096 35.3396 41.3838V37.3483C35.7324 36.9754 35.9537 36.4637 35.9537 35.9133C35.9537 34.8201 35.0642 33.9307 33.971 33.9307C32.8777 33.9307 31.9883 34.8201 31.9883 35.9133C31.9883 36.5096 32.2514 37.0633 32.7028 37.4378V41.3839C32.7029 42.1096 33.2943 42.7001 34.0213 42.7001ZM33.971 34.8095C34.5797 34.8095 35.0748 35.3046 35.0748 35.9132C35.0748 36.2625 34.9146 36.5838 34.6353 36.7948C34.5254 36.8778 34.4608 37.0077 34.4608 37.1455V41.3838C34.4608 41.625 34.2637 41.8211 34.0213 41.8211C33.779 41.8211 33.5819 41.625 33.5819 41.3838V37.2153C33.5819 37.0651 33.5052 36.9254 33.3787 36.8447C33.0584 36.6405 32.8672 36.2923 32.8672 35.9132C32.8672 35.3047 33.3624 34.8095 33.971 34.8095Z"
      fill="#F15622"
    />
  </svg>
);
