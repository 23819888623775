import React from 'react';

export const IconMessage = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_5720_23548)">
        <path
          d="M59.1 19.0452L49.3359 12.5358V9.96097C49.3359 8.99018 48.5489 8.20315 47.5781 8.20315H42.8368L30.975 0.295225C30.3845 -0.0984082 29.6154 -0.0984082 29.0249 0.295225L17.1632 8.20315H12.4219C11.4511 8.20315 10.6641 8.99018 10.6641 9.96097V12.5359L0.9 19.0453C0.410859 19.3712 0.117188 19.9201 0.117188 20.5078V50.9766C0.117188 51.9474 0.904219 52.7344 1.875 52.7344H24.3547L28.523 59.1952C29.2153 60.2684 30.7848 60.2684 31.4771 59.1952L35.6454 52.7344H58.125C59.0958 52.7344 59.8828 51.9474 59.8828 50.9766V20.5078C59.8828 19.9201 59.589 19.3712 59.1 19.0452ZM33.6907 35.7422L56.3672 23.4591V48.0252L33.6907 35.7422ZM54.7222 20.3519L49.3359 23.2694V16.761L54.7222 20.3519ZM30 3.87038L36.4991 8.20315H23.5009L30 3.87038ZM45.8203 11.7188V25.1738L30 33.743L14.1797 25.1737V11.7188H45.8203ZM10.6641 23.2694L5.27777 20.3517L10.6641 16.761V23.2694ZM34.6875 49.2188C34.0904 49.2188 33.5341 49.5219 33.2105 50.0236L30 54.9998L26.7895 50.0236C26.4659 49.5218 25.9096 49.2188 25.3125 49.2188H3.63281V23.4591L51.189 49.2188H34.6875Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5720_23548">
          <rect width="60" height="60" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
