import React from 'react';

export const IconFacebook = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
    <rect opacity="0.32" x="0.5" y="0.5" width="43" height="43" rx="3.5" stroke="#FBFBFE" />
    <g opacity="0.64">
      <path
        d="M23.5545 18.5018V15.7032C23.5545 14.9308 24.1691 14.3039 24.9263 14.3039H26.2982V10.8057H23.5545C21.2814 10.8057 19.439 12.685 19.439 15.0036V18.5018H16.6953V22H19.439V33.1942H23.5545V22H26.2982L27.67 18.5018H23.5545Z"
        fill="#FBFBFE"
      />
    </g>
  </svg>
);
