import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

export const IconHamburger = ({ activeMenuMobile, setActiveMenuMobile }) => {
  const router = useRouter();

  const toggleMenuMobile = () => setActiveMenuMobile((prevState) => !prevState);

  useEffect(() => {
    if (!activeMenuMobile) {
      toggleMenuMobile();
    }
  }, [router?.query]);

  return (
    <div
      className={classNames('icon-hamburger', { active: !activeMenuMobile })}
      onClick={toggleMenuMobile}
    />
  );
};
