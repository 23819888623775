export const IconTwitter = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
    <rect opacity="0.32" x="0.5" y="0.5" width="43" height="43" rx="3.5" stroke="#FBFBFE" />
    <g opacity="0.64">
      <path
        d="M9.5354 10.2101H16.9084L23.7234 19.3771L32.2064 10L34.222 10.0351L24.7283 20.6959L34.4644 33.79H27.0944L20.662 25.2473L12.8042 34H10.8207L19.6864 23.9986L9.5354 10.2101ZM16.2131 11.5639H12.3192L27.8977 32.4012H31.7215L16.2131 11.5639Z"
        fill="#FBFBFE"
      />
    </g>
  </svg>
);
