import React from 'react';

export const ButtonPlayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="148"
      height="148"
      fill="none"
      viewBox="0 0 148 148"
    >
      <circle
        cx="74"
        cy="74"
        r="53.818"
        fill="url(#paint0_linear_3409_14650)"
        opacity="0.4"
      ></circle>
      <circle
        cx="74"
        cy="74"
        r="72.5"
        stroke="url(#paint1_linear_3409_14650)"
        strokeWidth="3"
      ></circle>
      <path fill="#fff" d="M94.182 74L63.909 91.478V56.522L94.182 74z"></path>
      <defs>
        <linearGradient
          id="paint0_linear_3409_14650"
          x1="27.742"
          x2="71.457"
          y1="123.841"
          y2="2.168"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.339" stopColor="#1480BD"></stop>
          <stop offset="0.587" stopColor="#14358A"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_3409_14650"
          x1="10.395"
          x2="70.503"
          y1="142.532"
          y2="-24.768"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.339" stopColor="#1480BD"></stop>
          <stop offset="0.587" stopColor="#14358A"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
