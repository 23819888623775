import * as React from 'react';

export const IconOnDemand = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="68"
    height="68"
    fill="none"
    viewBox="0 0 68 68"
  >
    <circle
      cx="34"
      cy="34.001"
      r="27.074"
      fill="#CACDD5"
      opacity="0.2"
    ></circle>
    <circle cx="34" cy="34" r="33.5" stroke="#052148"></circle>
    <g fill="#052148" clipPath="url(#clip0_4678_13103)">
      <path d="M18.889 24.974c.171-.324.443-.418.804-.418 9.54.006 19.086.006 28.625.006.604 0 .793.182.793.789v7.174c0 .077.006.16-.006.236-.03.306-.296.553-.591.547a.61.61 0 01-.58-.56c-.006-.087-.006-.176-.006-.264v-6.38-.34H20.084v18.6h27.844v-.347-6.497c0-.424.213-.672.568-.683a.57.57 0 01.603.518c.012.136.006.277.006.412v6.998c0 .612-.19.807-.81.807H38.253v1.732h2.957c.508 0 .78.212.768.6-.011.377-.278.578-.769.578H26.78c-.125 0-.26-.006-.379-.047-.278-.089-.42-.325-.39-.613a.568.568 0 01.52-.513c.095-.011.195-.011.296-.011H29.723v-1.732h-.378c-3.205 0-6.41 0-9.622.006-.373 0-.68-.07-.846-.442.012-6.715.012-13.435.012-20.156zm12.06 22.3h6.103v-1.679h-6.104v1.68z"></path>
      <path d="M30.878 37.55c-.367 0-.598-.177-.657-.454a1.721 1.721 0 01-.03-.347v-6.94c0-.164.012-.34.077-.488.142-.33.52-.418.876-.212.804.453 1.596.919 2.4 1.378 1.267.73 2.538 1.455 3.804 2.186.615.353.615.848 0 1.201-2.04 1.178-4.081 2.35-6.127 3.522-.125.077-.279.124-.343.154zm.526-1.62c1.555-.896 3.064-1.761 4.613-2.657-1.555-.895-3.07-1.76-4.613-2.65v5.307zM36.04 41.69h-9.025c-.136 0-.278-.006-.408-.023a.578.578 0 01-.491-.542.575.575 0 01.414-.572c.118-.035.248-.047.378-.047h18.287c.136 0 .278.012.403.06.254.093.384.3.378.57a.56.56 0 01-.467.53c-.142.024-.296.03-.438.03-3.01 0-6.02-.006-9.03-.006zM21.852 41.09c0-.519-.006-1.037 0-1.556.006-.282.118-.524.402-.618.236-.076.473-.035.638.177.06.07.119.17.119.259.006 1.16.018 2.32 0 3.481-.006.289-.225.442-.515.471-.278.03-.479-.106-.585-.353-.053-.118-.06-.265-.065-.4 0-.484.006-.973.006-1.462zM25.182 41.09v1.407c0 .124-.006.253-.036.377-.065.265-.248.412-.52.436a.55.55 0 01-.586-.389 1.152 1.152 0 01-.053-.347 243.84 243.84 0 010-2.963c0-.094.018-.194.041-.289.077-.283.296-.447.58-.436.278.012.526.189.544.477.03.578.006 1.149.006 1.726h.024zM49.11 35.058a.607.607 0 01-.597.6.61.61 0 01-.58-.583.583.583 0 01.569-.589.581.581 0 01.609.572z"></path>
    </g>
    <defs>
      <clipPath id="clip0_4678_13103">
        <path
          fill="#fff"
          d="M0 0H30.222V23.926H0z"
          transform="translate(18.889 24.556)"
        ></path>
      </clipPath>
    </defs>
  </svg>
);
